@import "../../styles/variables.scss";

.buttonContainer {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 5px;

    img {
        width: 15px;
    }
}

.text {
    color: $buttonColor;
    font-family: Gilroy-Bold;
    font-size: 12px;
    line-height: 14px;
}